import React from 'react';
import Layout from '../../components/layout';
import Image from '../../atoms/image';
import SEO from '../../components/seo';
import { titles, descriptions } from '../../const/metaData';
import {
  BoldText,
  LineBreak,
  Wrapper,
  TextContainer,
  ImageContainer,
  ImageContent,
} from '../../styles/commonStyles';
import { paths } from '../../const/paths';
import { Link } from 'gatsby';

const Contact: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.contact} description={descriptions.cookies} />
      <Wrapper>
        <TextContainer>
          <h2>Contact Us</h2>
          <BoldText marginTop>Has your card been stolen?</BoldText>
          <p>
            Please cancel it immediately: go to the 'Account' tab, then 'Card
            settings' and follow the prompts. We'll make sure no one can spend
            on your old card, and send you a replacement.
          </p>
          <p>
            If you don’t have access to the app, please click{' '}
            <Link to={paths.emergencyAccess}>here</Link> to lock your card.
          </p>
          <LineBreak />
          <BoldText>Have you lost your card?</BoldText>
          <p>
            If you’ve lost your card, you can lock it in app immediately. Go to
            the 'Account' tab, then 'Card settings' and follow the prompts (if
            it does turn up you can then unlock it again from the app).
          </p>
          <LineBreak />
          <BoldText>Have you lost your phone and card?</BoldText>
          <p>
            If you’ve lost your phone and card, please click{' '}
            <Link to={paths.emergencyAccess}>here</Link> to lock your card.
          </p>
          <LineBreak />
          <BoldText>Need a hand?</BoldText>
          <p>
            Just tap the 'Support' tab in the Bó app and get in touch with us
            via chat. If you've lost your phone, email our Support team at{' '}
            <a href="mailto:bosupport@natwest.com">bosupport@natwest.com</a>.
          </p>
          <LineBreak />
          <BoldText>Journalist with a question?</BoldText>
          <p>
            Drop an email to Clair Whitefield at{' '}
            <a href="mailto:bocareers@natwest.com">
              clair.whitefield@natwest.com
            </a>
            .
          </p>
        </TextContainer>
        <ImageContainer>
          <ImageContent>
            <Image imagePath="hola" />
          </ImageContent>
        </ImageContainer>
      </Wrapper>
    </Layout>
  );
};

export default Contact;
